export const state = {
    all_sales_leads: [],
    all_sales_leads_loading_satus: null,

    marketing_banners: [],
    marketing_banners_loading_status: null,

    email_templates: [],
    email_templates_loading_status: null,

    selected_template_details: null,

    all_mailing_lists: [],
    all_mailing_lists_loading_status: null,

    selected_mailing_list: [],
    selected_mailing_list_loading_status: null,
    selected_mailing_list_details: [],

    clientids_to_save_to_mailing_list: [],
    completion_data: []
};

export const getters = {};

export const actions = {
    // setClientsLoadingStatus({ commit }, value) {
    //     commit("setClientsLoadingStatus", value);
    // },
    setAllSalesLeads({ commit }) {
        return new Promise((resolve, reject) => {
            commit("setAllSalesLeadsStatus", true);
            window.axios
                .get("api/crm/salesleads")
                .then((response) => {
                    commit("setAllSalesLeads", response.data);
                    commit("setAllSalesLeadsStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteSalesLead({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/deletelead", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    saveIncomingSalesLead({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/savelead", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response);
                });
        });
    },
    // marketing
    setMarketingBanners({ commit }) {
        return new Promise((resolve, reject) => {
            commit("setMarketingBannersLoadingStatus", true);
            window.axios
                .get("api/crm/banners")
                .then((response) => {
                    commit("setMarketingBanners", response.data);
                    commit("setMarketingBannersLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteMarketingBanner({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/deletebanner", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },

    // email templates

    //eslint-disable-next-line
    setEmailTemplates({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            commit("setEmailTemplatesLoadingStatus", true);
            window.axios
                .get("api/crm/emailtemplates", requestObject)
                .then((response) => {
                    commit("setEmailTemplates", response.data);
                    commit("setEmailTemplatesLoadingStatus", false);
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    saveEmailTemplate({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/savetemplate", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteEmailTemplate({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/deletetemplate", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedTemplateDetails({ commit }, data) {
        commit("setSelectedTemplateDetails", data);
    },

    //mailing lists
    setAllMailingLists({ commit }) {
        return new Promise((resolve, reject) => {
            commit("setAllMailingListsLoadingStatus", true);
            window.axios
                .get("api/crm/mailinglists")
                .then((response) => {
                    commit("setAllMailingLists", response.data);
                    commit("setAllMailingListsLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedMailingList({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit("setSelectedMailingListLoadingStatus", true);
            window.axios
                .get("api/crm/mailinglist", { params: id })
                .then((response) => {
                    commit("setSelectedMailingList", response.data);
                    commit("setSelectedMailingListLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    saveMailingList({ }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/savemailinglist", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedMailingListDetails({ commit }, data) {
        commit("setSelectedMailingListDetails", data);
    },

    addClientIdsToSaveToMailingList({ commit }, details) {
        commit("addClientIdsToSaveToMailingList", details);
    },
    removeClientIdsToSaveToMailingList({ commit }, details) {
        commit("removeClientIdsToSaveToMailingList", details);
    },
    //eslint-disable-next-line
    saveMailingListClients({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/savemailinglistclients", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteMailingList({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/deletemailinglist", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    sendMailingList({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/sendmailinglist", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    completeMembership({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/auth_membership", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    saveNewMember({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/add_member", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    getMembershipCompletionData({ commit }, token) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/crm/completion_data/" + token, )
                .then((response) => {
                    commit('setMembershipCompletionData', response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    finaliseMembership({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/crm/finalise_membership", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    setMembershipCompletionData(state, payload) {
        state.completion_data = payload;
    },
    setAllSalesLeadsStatus(state, payload) {
        state.all_sales_leads_loading_satus = payload;
    },
    setAllSalesLeads(state, payload) {
        state.all_sales_leads = payload;
    },
    setMarketingBanners(state, payload) {
        state.marketing_banners = payload;
    },
    setMarketingBannersLoadingStatus(state, payload) {
        state.marketing_banners_loading_status = payload;
    },
    setEmailTemplates(state, payload) {
        state.email_templates = payload;
    },
    setEmailTemplatesLoadingStatus(state, payload) {
        state.email_templates_loading_status = payload;
    },
    setSelectedTemplateDetails(state, payload) {
        state.selected_template_details = payload;
    },
    setAllMailingLists(state, payload) {
        state.all_mailing_lists = payload;
    },
    setAllMailingListsLoadingStatus(state, payload) {
        state.all_mailing_lists_loading_status = payload;
    },
    setSelectedMailingList(state, payload) {
        state.clientids_to_save_to_mailing_list = null;
        state.clientids_to_save_to_mailing_list = [];

        state.selected_mailing_list = payload.all_clients;

        // for (let i = 0; i < state.selected_mailing_list.length; i++) {
        //     if (state.selected_mailing_list[i].is_linked == true) {
        //         state.clientids_to_save_to_mailing_list.push(state.selected_mailing_list[i].id);
        //     }
        // }
    },
    setSelectedMailingListLoadingStatus(state, payload) {
        state.selected_mailing_list_loading_status = payload;
    },
    setSelectedMailingListDetails(state, payload) {
        state.selected_mailing_list_details = payload;
    },

    addClientIdsToSaveToMailingList(state, payload) {
        //only add if not already in the array
        const index = state.clientids_to_save_to_mailing_list.indexOf(payload);

        if (index === -1) {
            state.clientids_to_save_to_mailing_list.push(payload);
        }
    },
    removeClientIdsToSaveToMailingList(state, payload) {
        const index = state.clientids_to_save_to_mailing_list.indexOf(payload);

        state.clientids_to_save_to_mailing_list.splice(index, 1);
    },
};
