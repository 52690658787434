export const state = {
    core: [],
    branches: [],
    core_loaded: false,
    is_core_loading: false,
    //
    loggedIn_user_role: null,
    loggedIn_user_id: null,

    //new vars
    user_id: -1,
    user_role: null,
    user_client_id: -1,

    company_users: [],
    selected_userToEdit: null,
    user_roles: [],

    selected_branch_id: -1,
};

export const getDefaultState = () => {
    return {
        core: [],
        branches: [],
        core_loaded: false,
        is_core_loading: false,
        //
        loggedIn_user_role: null,
        loggedIn_user_id: null,

        //new vars
        user_id: -1,
        user_role: null,
        user_client_id: -1,

        company_users: [],
        user_roles: [],

        selected_branch_id: -1,
    };
};

export const getters = {
    //eslint-disable-next-line
    getUserId(state) {
        return state.user_id;
    },
    getUserRole(state) {
        return state.user_role;
    },
    getClientId(state) {
        return state.user_client_id;
    },
    getValidUserRoute(state) {
        if (state.user_role == "admin") {
            return "/members";
        }
        if (state.user_role == "stockist" || state.user_role == "individual") {
            return "/clients/view/" + state.user_client_id;
        }
        if (state.user_role == "production") {
            return "/orders";
        }
        if (state.user_role == "reseller") {
            return `/reps/view/${state.user_id}`;
        }
        return "undef";
    },
};

export const actions = {
    loadCoreData({ commit }) {
        commit("setPreLoadState", true);
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/auth/core")
                .then((response) => {
                    commit("setCoreData", response.data);
                    if (window.localStorage.getItem("selected_branch_id") != null) {
                        commit("setSelectedBranch", window.localStorage.getItem("selected_branch_id"));
                    } else {
                        //* check if the branch_config array has an item where 'default_branch' == 1 and set that branch as the selected item
                        let default_branch = response.data.branch_config.find((branch) => branch.default_branch == 1);
                        if (default_branch) {
                            //set selected branch id to the default_branch id
                            commit("setSelectedBranch", default_branch.id);
                        } else {
                            // if no default branch exists, set the id to -1
                            commit("setSelectedBranch", -1);
                        }
                    }
                    // set loading state
                    commit("setCoreLoadedState", true);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedBranch({ commit }, branch_id) {
        commit("setSelectedBranch", branch_id);
    },
    getBranches({commit}) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/admin/branches")
                .then((response) => {
                    commit("setBranches", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //
    setCompanyUsers({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/admin/companyusers")
                .then((response) => {
                    commit("setCompanyUsers", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setUserRoles({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/admin/roles")
                .then((response) => {
                    commit("setUserRoles", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    createUser({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/createuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    handleLogout({ commit }) {
        return new Promise((resolve) => {
            commit("resetState");
            resolve();
        });
    },
    //eslint-disable-next-line
    deleteCompanyUser({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/deleteuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //method will be called when wanting to edit a user
    setSelectedUserToEdit({ commit }, user) {
        commit("setSelectedUserToEdit", user);
    },
    //eslint-disable-next-line
    updateUser({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/updateuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setBranches(state, payload) {
        state.branches = payload;
    },
    setCoreData(state, payload) {
        //eslint-disable-next-line

        state.core = payload;
        // NB
        state.loggedIn_user_id = payload.id;
        state.loggedIn_user_role = payload.role.id;
        //
        state.user_id = payload.id;
        state.user_role = payload.role_name;

        //only set if there is data to be set
        if (payload.client) {
            state.user_client_id = payload.client.id;
        }
    },
    setCoreLoadedState(state, payload) {
        state.core_loaded = payload;
        state.is_core_loading = false;
    },
    setPreLoadState(state, payload) {
        state.is_core_loading = payload;
    },

    setSelectedBranch(state, payload) {
        window.localStorage.setItem("selected_branch_id", payload);
        state.selected_branch_id = payload;
    },
    setCompanyUsers(state, payload) {
        state.company_users = payload;
    },
    setUserRoles(state, payload) {
        state.user_roles = payload;
    },
    setSelectedUserToEdit(state, payload) {
        state.selected_userToEdit = payload;
    },
};
